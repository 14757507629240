import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Apiarist from './apiarist';
import Box from './box';
import Frame from './frame';
import Dashboard from './dashboard';
import LocalHub from './localHub';
import Production from './production';
import Shipping from './shipping';
import StainlessTank from './stainlessTank';
import withAdminLayout from '../../layout/withAdminLayout';

const CentralizeHarvest = lazy(() => import('../../container/centralize-harvest/CentralizeHarvest'));
const HarvestRequest = lazy(() => import('../../container/harvest/HarvestRequest'));
const Management = lazy(() => import('../../container/user/Management'));
const Nectar = lazy(() => import('../../container/nectar'));
const NotFound = lazy(() => import('../../container/pages/404'));
const PreHarvest = lazy(() => import('../../container/pre-harvest/PreHarvest'));
const Product = lazy(() => import('../../container/product'));
const ProductVariant = lazy(() => import('../../container/product/Variant'));
const Profile = lazy(() => import('../../container/profile/user'));
const Seal = lazy(() => import('../../container/seal'));
const Setting = lazy(() => import('../../container/setting/index'));
const ShippingBox = lazy(() => import('../../container/shipping-box/ShippingBox'));
const CreateShippingBox = lazy(() => import('../../container/shipping-box/CreateShippingBox'));
const Species = lazy(() => import('../../container/species'));
const WeightVerification = lazy(() => import('../../container/harvest/WeightVerification'));
const TrigonaWeightVerification = lazy(() => import('../../container/harvest/TrigonaWeightVerification'));

const Home = () => {
  const { path } = useRouteMatch();
  const user = useSelector((state) => state.auth.user);

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Switch>
        <Route exact path={`${path}dashboard`} component={Dashboard} />
        <Route path={`${path}apiarist`} component={Apiarist} />
        {(user.role === 'admin' || user.role === 'manajemen') && (
          <Route path={`${path}local-hub`} component={LocalHub} />
        )}
        {user.role === 'admin' && <Route path={`${path}management`} component={Management} />}
        {user.role === 'hub lokal' && <Route exact path={`${path}harvest-request`} component={HarvestRequest} />}
        {user.role === 'hub lokal' && <Route exact path={`${path}pre-harvest`} component={PreHarvest} />}
        {user.role === 'hub lokal' && (
          <Route exact path={`${path}weight-verification`} component={WeightVerification} />
        )}
        {user.role === 'hub lokal' && (
          <Route exact path={`${path}trigona-weight-verification`} component={TrigonaWeightVerification} />
        )}
        {(user.role === 'manajemen' || user.role === 'hub lokal') && (
          <Route path={`${path}shipping`} component={Shipping} />
        )}
        {(user.role === 'manajemen' || user.role === 'admin') && (
          <Route path={`${path}production`} component={Production} />
        )}
        {user.role === 'hub lokal' && <Route exact path={`${path}shipping-box`} component={ShippingBox} />}
        {user.role === 'hub lokal' && <Route exact path={`${path}shipping-box/create`} component={CreateShippingBox} />}
        {['hub lokal', 'manajemen'].includes(user.role) && (
          <Route path={`${path}stainless-tank`} component={StainlessTank} />
        )}
        {user.role === 'manajemen' && <Route path={`${path}centralize-harvest`} component={CentralizeHarvest} />}
        <Route path={`${path}box`} component={Box} />
        <Route path={`${path}frame`} component={Frame} />
        {user.role === 'admin' && <Route exact path={`${path}setting`} component={Setting} />}
        <Route exact path={`${path}seal`} component={Seal} />
        {user.role === 'admin' && <Route exact path={`${path}nectar`} component={Nectar} />}
        {user.role === 'admin' && <Route exact path={`${path}product`} component={Product} />}
        {user.role === 'admin' && <Route exact path={`${path}product/:productId/variant`} component={ProductVariant} />}
        {user.role === 'admin' && <Route exact path={`${path}species`} component={Species} />}
        <Route path={`${path}profile`} component={Profile} />
        <Route exact path={['/sign-in', '/']}>
          <Redirect to={`${path}dashboard`} />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default withAdminLayout(Home);
