import React from 'react';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const role = useSelector((state) => state.auth.user.role);
  const { path } = useRouteMatch();
  const pathName = window.location.pathname;
  const mainPathSplit = pathName.split('/');
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit[1] !== '' ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 0) setOpenKeys([]);
  };

  const selectedKeys = () => {
    const keys = [];
    switch (mainPathSplit.length) {
      case 1:
        if (mainPathSplit[0] === '') {
          keys[0] = 'dashboard';
        } else {
          [keys[0]] = mainPathSplit;
        }
        break;

      default:
        [, keys[0]] = mainPathSplit;
        break;
    }
    return keys;
  };

  const items = [
    {
      type: 'group',
      label: 'Main Menu',
      children: [
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}dashboard`}>
              Dashboard
            </NavLink>
          ),
          key: 'dashboard',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}dashboard`}>
              <FeatherIcon icon="grid" />
            </NavLink>
          ),
        },
        role === 'admin' && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}nectar`}>
              Nectar
            </NavLink>
          ),
          key: 'nectar',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}nectar`}>
              <FeatherIcon icon="slack" />
            </NavLink>
          ),
        },
        role === 'admin' && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}species`}>
              Spesies
            </NavLink>
          ),
          key: 'species',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}species`}>
              <FeatherIcon icon="list" />
            </NavLink>
          ),
        },
        role === 'admin' && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}product`}>
              Produk
            </NavLink>
          ),
          key: 'product',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}product`}>
              <FeatherIcon icon="grid" />
            </NavLink>
          ),
        },
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}box`}>
              Box
            </NavLink>
          ),
          key: 'box',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}box`}>
              <FeatherIcon icon="box" />
            </NavLink>
          ),
        },
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}frame`}>
              Frame
            </NavLink>
          ),
          key: 'frame',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}frame`}>
              <FeatherIcon icon="sidebar" />
            </NavLink>
          ),
        },
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}seal`}>
              Segel
            </NavLink>
          ),
          key: 'seal',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}seal`}>
              <FeatherIcon icon="lock" />
            </NavLink>
          ),
        },
      ],
    },
    role === 'hub lokal' && {
      type: 'group',
      label: 'Pengajuan Panen',
      children: [
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}pre-harvest`}>
              Verifikasi Pra Panen
            </NavLink>
          ),
          key: 'pre-harvest',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}pre-harvest`}>
              <FeatherIcon icon="clipboard" />
            </NavLink>
          ),
        },
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}harvest-request`}>
              Pengajuan Panen
            </NavLink>
          ),
          key: 'harvest-request',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}harvest-request`}>
              <FeatherIcon icon="package" />
            </NavLink>
          ),
        },
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}weight-verification`}>
              QC Frame
            </NavLink>
          ),
          key: 'weight-verification',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}weight-verification`}>
              <FeatherIcon icon="clipboard" />
            </NavLink>
          ),
        },
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}trigona-weight-verification`}>
              QC Madu Cair
            </NavLink>
          ),
          key: 'trigona-weight-verification',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}trigona-weight-verification`}>
              <FeatherIcon icon="clipboard" />
            </NavLink>
          ),
        },
      ],
    },
    (role === 'hub lokal' || role === 'manajemen') && {
      type: 'group',
      label: 'Pengiriman',
      children: [
        role === 'hub lokal' && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}shipping-box`}>
              Box Pengiriman
            </NavLink>
          ),
          key: 'shipping-box',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}shipping-box`}>
              <FeatherIcon icon="package" />
            </NavLink>
          ),
        },
        role === 'hub lokal' && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}stainless-tank`}>
              Stainless Tank
            </NavLink>
          ),
          key: 'stainless-tank',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}stainless-tank`}>
              <FeatherIcon icon="database" />
            </NavLink>
          ),
        },
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}shipping`}>
              Pengiriman
            </NavLink>
          ),
          key: 'shipping',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}shipping`}>
              <FeatherIcon icon="truck" />
            </NavLink>
          ),
        },
      ],
    },
    (role === 'manajemen' || role === 'admin') && {
      type: 'group',
      label: 'Produksi',
      children: [
        role === 'manajemen' && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}centralize-harvest`}>
              QC Frame
            </NavLink>
          ),
          key: 'centralize-harvest',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}centralize-harvest`}>
              <FeatherIcon icon="database" />
            </NavLink>
          ),
        },
        role === 'manajemen' && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}stainless-tank/re-weighing`}>
              QC Madu Cair
            </NavLink>
          ),
          key: '/stainless-tank/re-weighing',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}stainless-tank/re-weighing`}>
              <FeatherIcon icon="database" />
            </NavLink>
          ),
        },
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}production`}>
              Produksi
            </NavLink>
          ),
          key: 'production',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}production`}>
              <FeatherIcon icon="archive" />
            </NavLink>
          ),
        },
      ],
    },
    {
      type: 'group',
      label: 'Pengguna',
      children: [
        (role === 'admin' || role === 'manajemen') && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}local-hub`}>
              Hub Lokal
            </NavLink>
          ),
          key: 'local-hub',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}local-hub`}>
              <FeatherIcon icon="users" />
            </NavLink>
          ),
        },
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}apiarist`}>
              Peternak Lebah
            </NavLink>
          ),
          key: 'apiarist',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}apiarist`}>
              <FeatherIcon icon="users" />
            </NavLink>
          ),
        },
        role === 'admin' && {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}management`}>
              Manajemen
            </NavLink>
          ),
          key: 'management',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}management`}>
              <FeatherIcon icon="user" />
            </NavLink>
          ),
        },
      ],
    },
    role === 'admin' && {
      type: 'group',
      label: 'Pengaturan',
      children: [
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}setting`}>
              Setting
            </NavLink>
          ),
          key: 'setting',
          icon: !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}setting`}>
              <FeatherIcon icon="settings" />
            </NavLink>
          ),
        },
      ],
    },
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={!topMenu ? selectedKeys() : []}
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 1 ? mainPathSplit[0] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
      selectedKeys={selectedKeys()}
      items={items}
    />
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
