import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const NotFound = lazy(() => import('../../container/pages/404'));
const LocalHubs = lazy(() => import('../../container/localHub'));
const Create = lazy(() => import('../../container/localHub/Create'));
const Edit = lazy(() => import('../../container/localHub/Edit'));
const Show = lazy(() => import('../../container/localHub/Show'));

function LocalHubRoutes() {
  const { path } = useRouteMatch();
  const user = useSelector((state) => state.auth.user);

  return (
    <Switch>
      <Route exact path={`${path}`} component={LocalHubs} />
      {user.role === 'admin' && <Route exact path={`${path}/create`} component={Create} />}
      <Route exact path={`${path}/:localHubId`} component={Show} />
      {user.role === 'admin' && <Route exact path={`${path}/:localHubId/edit`} component={Edit} />}
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default LocalHubRoutes;
