import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const NotFound = lazy(() => import('../../container/pages/404'));
const Apiarists = lazy(() => import('../../container/apiarist'));
const Create = lazy(() => import('../../container/apiarist/Create'));
const Edit = lazy(() => import('../../container/apiarist/Edit'));
const Show = lazy(() => import('../../container/apiarist/Show'));

function ApiaristRoutes() {
  const { path } = useRouteMatch();
  const user = useSelector((state) => state.auth.user);

  return (
    <Switch>
      <Route exact path={`${path}`} component={Apiarists} />
      {user.role === 'admin' && <Route exact path={`${path}/create`} component={Create} />}
      <Route exact path={`${path}/:apiaristId`} component={Show} />
      {user.role === 'admin' && <Route exact path={`${path}/:apiaristId/edit`} component={Edit} />}
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default ApiaristRoutes;
