import { notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  getSettingBegin,
  getSettingSuccess,
  getSettingErr,
  updateSettingBegin,
  updateSettingSuccess,
  updateSettingErr,
} = actions;

const getSetting = () => {
  return async (dispatch) => {
    try {
      dispatch(getSettingBegin());

      const response = await DataService.get('/setting');
      dispatch(
        getSettingSuccess({
          appName: response.data.setting.app_name,
          logo: response.data.setting.logo_url,
          smallLogo: response.data.setting.small_logo_url,
          authImage: response.data.setting.auth_image_url,
        }),
      );
    } catch (err) {
      dispatch(getSettingErr(err));
    }
  };
};

const updateSetting = (data) => {
  return async (dispatch) => {
    try {
      dispatch(updateSettingBegin());

      const response = await DataService.post('/setting', data);

      dispatch(
        updateSettingSuccess({
          appName: response.data.setting.app_name,
          logo: response.data.setting.logo_url,
          smallLogo: response.data.setting.small_logo_url,
          authImage: response.data.setting.auth_image_url,
        }),
      );

      notification.success({
        message: 'Pengaturan berhasil diperbaharui !',
      });
    } catch (err) {
      dispatch(updateSettingErr(err));
    }
  };
};

export { getSetting, updateSetting };
