import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Seal = lazy(() => import('../container/print/seal'));
const BoxQR = lazy(() => import('../container/print/qr/box'));
const BoxQRIds = lazy(() => import('../container/print/qr/box-id'));
const FrameQR = lazy(() => import('../container/print/qr/frame'));
const FrameQRIds = lazy(() => import('../container/print/qr/frame-ids'));
const SingleQR = lazy(() => import('../container/print/qr/single'));
const ShippingBox = lazy(() => import('../container/print/shipping-box'));
const StainlessTank = lazy(() => import('../container/print/stainless-tank'));

function PrintRoutes() {
  const { path } = useRouteMatch();

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Switch>
        <Route path={`${path}/seal`} component={Seal} />
        <Route path={`${path}/qr/frame-id`} component={FrameQRIds} />
        <Route path={`${path}/qr/frame/:apiaristId/:mode/:layout`} component={FrameQR} />
        <Route path={`${path}/qr/box/box-id`} component={BoxQRIds} />
        <Route path={`${path}/qr/box/:boxType/:apiaristId/:mode`} component={BoxQR} />
        <Route path={`${path}/qr/:code`} component={SingleQR} />
        <Route path={`${path}/shipping-box/:shippingBoxId`} component={ShippingBox} />
        <Route path={`${path}/stainless-tank/:stainlessTankId`} component={StainlessTank} />
      </Switch>
    </Suspense>
  );
}

export default PrintRoutes;
