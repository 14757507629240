import { notification } from 'antd';
import Cookies from 'js-cookie';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { removeItem, setItem } from '../../utility/localStorageControl';

const {
  loginBegin,
  loginSuccess,
  loginErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
  updateProfileBegin,
  updateProfileSuccess,
  updateProfileErr,
} = actions;

const login = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(loginBegin());
      const response = await DataService.post('/login', data);

      if (response.data.user.role === 'peternak lebah') {
        dispatch(loginErr({}));

        notification.error({
          message: 'Tidak bisa login menggunakan akun peternak lebah !',
        });
      } else {
        setItem('access_token', response.data.token);
        setItem('user', response.data.user);

        Cookies.set('logedIn', true);
        await dispatch(loginSuccess(response.data.user));

        notification.success({
          message: 'Login berhasil !',
        });
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const logOut = () => {
  return async (dispatch) => {
    try {
      dispatch(logoutBegin());
      await DataService.post('/logout');

      removeItem('access_token');
      removeItem('user');

      Cookies.remove('logedIn');
      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

const updateProfile = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(updateProfileBegin());
      const response = await DataService.post('/profile', data);

      setItem('user', response.data.user);

      await dispatch(updateProfileSuccess(response.data.user));

      notification.success({
        message: 'Profil berhasil diperbaharui !',
      });
    } catch (err) {
      dispatch(updateProfileErr(err));
    }
  };
};

const updateProfilePicture = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(updateProfileBegin());
      const response = await DataService.post('/profile-picture', data);

      setItem('user', response.data.user);

      await dispatch(updateProfileSuccess(response.data.user));

      notification.success({
        message: 'Profil berhasil diperbaharui !',
      });
    } catch (err) {
      dispatch(updateProfileErr(err));
    }
  };
};

export { login, logOut, updateProfile, updateProfilePicture };
