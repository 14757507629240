import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';

const NotFound = lazy(() => import('../../container/pages/404'));
const Index = lazy(() => import('../../container/stainless-tank/StainlessTank'));
const Create = lazy(() => import('../../container/stainless-tank/CreateStainlessTank'));
const ReWeighing = lazy(() => import('../../container/stainless-tank/ReWeighing'));

function LocalHubRoutes() {
  const { path } = useRouteMatch();
  const user = useSelector((state) => state.auth.user);

  return (
    <Switch>
      {user.role === 'hub lokal' && (
        <>
          <Route exact path={`${path}`} component={Index} />
          <Route exact path={`${path}/create`} component={Create} />
          <Route exact path={`${path}/frame/:harvestFrameId/harvest`} component={Index} />
          <Route exact path={`${path}/box/:harvestBoxId/harvest`} component={Index} />
        </>
      )}
      {user.role === 'manajemen' && <Route exact path={`${path}/re-weighing`} component={ReWeighing} />}
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default LocalHubRoutes;
