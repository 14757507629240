import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';

const SignIn = lazy(() => import('../container/profile/authentication/overview/SignIn'));

function NotFound() {
  return <Redirect to="/sign-in" />;
}

function FrontendRoutes() {
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Switch>
        <Route exact path="/sign-in" component={SignIn} />
        <Route exact path="*" component={NotFound} />
      </Switch>
    </Suspense>
  );
}

export default AuthLayout(FrontendRoutes);
