import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Frame = lazy(() => import('../../container/frame'));
const Detail = lazy(() => import('../../container/frame/Detail'));
const NotFound = lazy(() => import('../../container/pages/404'));

function FrameRoutes() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Frame} />
      <Route exact path={`${path}/:frameId`} component={Detail} />
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default FrameRoutes;
