import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { getSetting } from './redux/setting/actionCreator';
import store from './redux/store';
import Print from './routes/print';
import Home from './routes/home';
import Auth from './routes/auth';
import './static/css/style.css';
import config from './config/config';
import ProtectedRoute from './components/utilities/protectedRoute';
import 'antd/dist/antd.less';

const { theme } = config;

function ProviderConfig() {
  const dispatch = useDispatch();
  const { rtl, isLoggedIn, topMenu, darkMode, setting } = useSelector((state) => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      isLoggedIn: state.auth.login,
      setting: state.setting,
    };
  });

  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    dispatch(getSetting());
  }, []);

  useEffect(() => {
    document.title = setting.appName || '';
  }, [setting.appName]);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <Router basename={process.env.PUBLIC_URL}>
          {!isLoggedIn ? (
            <Route path="/" component={Auth} />
          ) : path.startsWith(`${process.env.PUBLIC_URL}/print`) ? (
            <ProtectedRoute path="/print" component={Print} />
          ) : (
            <ProtectedRoute path="/" component={Home} />
          )}
          {isLoggedIn &&
            (path === process.env.PUBLIC_URL ||
              path === `${process.env.PUBLIC_URL}/` ||
              path === `${process.env.PUBLIC_URL}/sign-in`) && <Redirect to="/dashboard" />}
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default hot(App);
