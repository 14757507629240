import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const NotFound = lazy(() => import('../../container/pages/404'));
const Shippings = lazy(() => import('../../container/shipping/Shipping'));
const Create = lazy(() => import('../../container/shipping/CreateShipping'));

function ShippingRoutes() {
  const { path } = useRouteMatch();
  const user = useSelector((state) => state.auth.user);

  return (
    <Switch>
      <Route exact path={`${path}`} component={Shippings} />
      {user.role === 'hub lokal' && <Route exact path={`${path}/create`} component={Create} />}
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default ShippingRoutes;
