import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Box = lazy(() => import('../../container/box'));
const Detail = lazy(() => import('../../container/box/Detail'));
const NotFound = lazy(() => import('../../container/pages/404'));

function BoxRoutes() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Box} />
      <Route exact path={`${path}/:boxId`} component={Detail} />
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default BoxRoutes;
